import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import { SETTINGS } from '../constant/setting'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ttxid: 'tx0LCHIQnvsUjUAGY6no28VrzpEstO',
    token: '133DB0B09F8A2AE88148A1E75BEAF4716412CEE7',
    timestamp: '1599546229546',
    isLoading: false,
    error: false,
    hotels: [],
    attractions: [
      {
        name: '台北華山文創園區',
        description:
          '華山文創園區展覽是大重點喔。很多超紅台北展覽都辦在這呢，再來華山美食餐廳咖啡廳、台灣麵食、早午餐等氛圍好人氣必訪。還有華山景點、電影院，以及磚屋、石牆、樹林等IG熱門打卡場景，到處有冷氣、屋簷遮蔽不怕日曬雨淋，台北一日遊快來這吧。'
      },
      {
        name: '台北松菸',
        description:
          '松菸展覽、松菸美食餐廳，松菸誠品店都是特色，和華山文創園區類似，很多當紅台北展覽都喜歡辦在這裡呢。松菸美食餐廳主要在松菸誠品裡，咖啡廳、鬆餅、麵食等，園區外也不少。吃飽後還可以到松菸電影院放鬆，或到生態景觀池逛逛，首屈一指的台北景點，親子情侶來訪都適合又充實～'
      },
      {
        name: '黃金瀑布',
        description:
          '由來是礦產＆雨水化學作用所形成，水有毒！近看時頗為驚嘆，記得和黃金瀑布附近景點，陰陽海、十三層遺址、黃金博物館、報時山景觀台、無耳茶壺山等，一堆精彩景點一起玩就對了，美食則要去九份老街會比較有收穫。'
      }
    ]
  },
  getters: {
    getHotels: state => style => {
      return state.hotels.filter(function(item) {
        if (style === '') {
          return true
        }
        return item.style === style
      })
    },
    getHotelById: state => hotelId => {
      return state.hotels.find(item => item.id == hotelId)
    },
    getAttractionsById: state => hotelId => {
      return state.attractions[hotelId % 3]
    },
    getHighestPrice: state => {
      if (state.hotels.length > 0) {
        return state.hotels.sort(function(a, b) {
          return b.price - a.price
        })[0].price
      } else {
        return 0
      }
    }
  },
  mutations: {
    toggleLoading(state) {
      state.isLoading = !state.isLoading
    },
    cleanError(state) {
      state.error = false
    },
    setError(state) {
      state.error = true
    },
    setHotels(state, payload) {
      state.hotels = payload.data
    }
  },
  actions: {
    async getImageUploadInfo({ state }) {
      // commit(FORTUNE_MUTATION.ADD_LOADING)
      let payload = {}
      payload.ttxid = state.ttxid
      payload.token = state.token
      payload.timestamp = state.timestamp
      payload.project = SETTINGS.PROJECT_NAME

      try {
        let res = await axios.get(
          // 'https://api.taotaoxi.net/face-reading/upload-info',
          'https://api.taotaoxi.net/face-reading-must/upload-info',
          {
            params: payload
          }
        )
        return res.data
      } catch (err) {
        throw err
      } finally {
        // commit(FORTUNE_MUTATION.MINUS_LOADING)
      }
    },
    async getResult2({ dispatch }, payload) {
      let imageId = payload.imageId
      try {
        let res = await axios.get(
          'https://api.taotaoxi.net/face-reading-must/tourism/result/' + imageId
        )

        if (payload.times > 30) {
          throw 'timeout'
        } else if (res.data.message === 'PENDING') {
          payload.times++
          await new Promise(resolve => setTimeout(resolve, 1000))
          return await dispatch('getResult2', payload)
        } else {
          return res.data
        }
      } catch (err) {
        throw err
      } finally {
        // commit(FORTUNE_MUTATION.MINUS_LOADING)
      }
    },
    async init({ commit }) {
      var postData = {}
      // postData.ttxid = state.ttxid
      // postData.timestamp = state.timestamp
      // postData.token = state.token
      try {
        let res = await axios.get(
          'https://api.taotaoxi.net/face-reading-must/tourism/hotel',
          { params: postData }
        )
        commit('setHotels', res)
      } catch (err) {
        throw err
      }
    },
    async getStatistics({ state }) {
      var payload = {}
      payload.ttxid = state.ttxid
      payload.timestamp = state.timestamp
      payload.token = state.token
      try {
        let res = await axios.get(
          'https://api.taotaoxi.net/face-reading-must/tourism/statistics',
          { params: payload }
        )

        return res.data
      } catch (err) {
        throw err
      }
    },
    async postTrace({}, payload) {
      let requestData = {
        price: payload.price,
        ttxImageId: payload.imageId,
        productId: payload.productId,
        productName: payload.productName,
        project: SETTINGS.PROJECT_NAME
      }
      await axios.post(
        'https://api.taotaoxi.net/face-reading-must/tourism/trace',
        requestData
      )
    }
  },
  modules: {}
})

// async function delay(ms) {
//   // return await for better async stack trace support in case of errors.
//   return await new Promise(resolve => setTimeout(resolve, ms))
// }
