<template>
  <main id="main">
    <section id="card1" class="card d-flex flex-column align-center">
      <div class="profile flex-1 w-100">
        <page3-attributes
          class="attrs"
          v-if="result.position"
          :img-url="imgUrl"
          :face-top="result.position.top"
          :face-left="result.position.left"
          :face-width="result.position.width"
          :face-height="result.position.height"
          :eye-tag="result.eye.tag"
          :eye-keyword="result.eye.keyword"
          :brow-tag="result.brow.tag"
          :brow-keyword="result.brow.keyword"
          :nose-tag="result.nose.tag"
          :nose-keyword="result.nose.keyword"
          :mouth-tag="result.mouth.tag"
          :mouth-keyword="result.mouth.keyword"
          :shape-tag="result.shape.tag"
          :shape-keyword="result.shape.keyword"
        />
        <!-- <img class="attrs" src="@/assets/page-3-attributes.svg" alt="" /> -->
      </div>
      <div class="w-100">
        <img class="line w-100" src="@/assets/page-3-line.svg" alt="" />
      </div>
      <div class="d-flex flex-column align-center">
        <h2 class="title" v-if="result.fiveElement">
          【{{ result.fiveElement.motto[0] }}】【{{
            result.fiveElement.motto[1]
          }}】
        </h2>
        <div class="content">
          <p class="text" v-if="result.shape">
            {{ result.shape.tag }}｜{{ result.shape.shortDesc }}
          </p>
          <p class="text" v-if="result.brow">
            {{ result.brow.tag }}｜{{ result.brow.shortDesc }}
          </p>
          <p class="text" v-if="result.eye">
            {{ result.eye.tag }}｜{{ result.eye.shortDesc }}
          </p>
          <p class="text" v-if="result.nose">
            {{ result.nose.tag }}｜{{ result.nose.shortDesc }}
          </p>
          <p class="text" v-if="result.mouth">
            {{ result.mouth.tag }}｜{{ result.mouth.shortDesc }}
          </p>
        </div>
      </div>
    </section>
    <section id="card2" class="card d-flex flex-column align-center">
      <page3-title
        class="title"
        v-if="result.score"
        :score="result.score"
        :category="result.style"
      />
      <!-- <img class="title" src="@/assets/page-3-title.svg" alt="" /> -->
      <img class="line" src="@/assets/page-3-line2.svg" alt="" />
      <h2 class="slogan">這&nbsp;間&nbsp;房&nbsp;間&nbsp;適&nbsp;合&nbsp;你</h2>
      <img class="line" src="@/assets/page-3-line2.svg" alt="" />
      <div class="room-wrapper">
        <div
          class="room d-flex justify-center"
          v-for="(item, index) in recommendHotel"
          :key="index"
        >
          <div
            class="img d-flex flex-column justify-center"
            :style="{ backgroundImage: 'url(' + getHotelImg(item.id) + ')' }"
          ></div>
          <!-- <img class="img" :src="getHotelImg(item.id)" /> -->
          <div class="intro flex-1 d-flex flex-column justify-center">
            <div class="name">{{ item.description }}</div>
            <div class="price">NT {{ item.price }}</div>
          </div>
          <div class="button-wrapper d-flex align-center justify-center">
            <img
              class="button-hover"
              src="@/assets/page-3-next.svg"
              alt=""
              v-if="index == 0"
            />
            <img
              class="button"
              src="@/assets/page-3-choose.svg"
              @click="toDetail(item.id)"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="card3" class="card d-flex flex-column" v-if="recommendHotel">
      <h1 class="title">
        <span class="prefix">週末就醬玩｜</span>
        <span class="postfix">{{ recommendHotel[0].attractionName }}</span>
      </h1>
      <div
        class="place-img"
        :style="{
          backgroundImage:
            'url(' + getAttractionsImg(recommendHotel[0].id) + ')'
        }"
      ></div>
      <p
        class="place-intro"
        v-if="recommendHotel[0].attractionDescription.length > 50"
      >
        {{ recommendHotel[0].attractionDescription.substring(0, 50) }}...
      </p>
      <p class="place-intro" v-else>
        {{ recommendHotel[0].attractionDescription }}
      </p>
      <div>
        <img src="@/assets/page-3-line2.svg" alt="" />
      </div>
      <div class="button-wrapper d-flex justify-center">
        <img
          class="button"
          src="@/assets/page-3-recamera2.svg"
          @click="toHome()"
        />
        <img class="button" src="@/assets/page-3-more.svg" @click="toMore()" />
      </div>
    </section>
  </main>
</template>

<script>
import store from '@/store'

import Page3Attributes from '@/components/Page3Attributes'
import Page3Title from '@/components/Page3Title'

// const MOCK_IMAGE_ID = 'ttxi282edd6cad534e959d5910f02c0d5c68'

export default {
  name: 'Result',
  data() {
    return { result: {}, imgUrl: '' }
  },
  components: { Page3Title, Page3Attributes },
  mounted: function() {
    let vm = this
    vm.$store.commit('toggleLoading')
    // console.log(this.$route.params)
    let imageId = this.$route.params.imageId
    this.imgUrl =
      'https://upload-face-reading-must.taotaoxi.net/upload-picture/' +
      imageId +
      '.png'
    if ('undefined' === typeof this.$route.params.result) {
      store
        .dispatch('getResult2', { imageId: imageId, times: 0 })
        .then(function(res) {
          vm.result = res
          vm.$store.commit('toggleLoading')
          new Audio(require('../assets/vocal/3.mp3')).play()
        })
    } else {
      this.result = this.$route.params.result
      vm.$store.commit('toggleLoading')
    }
  },
  computed: {
    recommendHotel: function() {
      let hotels = []
      // let defaultItem = this.defaultItem
      for (let id in this.result.defaultItem) {
        hotels.push(this.$store.getters.getHotels(this.result.style)[id])
      }
      return hotels
    },
    attraction: function() {
      return this.$store.getters.getAttractionsById(
        this.recommendHotel[0].id % 3
      )
    }
  },
  methods: {
    setResult: function(data) {
      this.result = data
    },
    toHome: function() {
      this.$router.push({ name: 'Home' })
    },
    toMore: function() {
      this.$router.push({
        name: 'More',
        params: { imageId: this.$route.params.imageId }
      })
    },
    getHotelImg: function(filename) {
      return require('../assets/hotel/' + filename + '.jpg')
    },
    getAttractionsImg: function(filename) {
      return require('../assets/attractions/' + padLeft(filename, 4) + '.jpg')
    },
    toDetail: function(hotelId) {
      this.$router.push({
        name: 'Detail',
        params: { hotelId: hotelId, imageId: this.$route.params.imageId }
      })
    }
  }
}

function padLeft(str, len) {
  str = '' + str
  return str.length >= len
    ? str
    : new Array(len - str.length + 1).join('0') + str
}
</script>

<style scoped>
#main {
  background-image: url('../assets/page-3-background.svg');
}
.card {
  position: fixed;
  padding: 1%;
  box-sizing: border-box;
  /* opacity: 0.7; */
  /* background: #bf6666; */
}
#card1 {
  top: 19%;
  left: 6%;
  height: 68vh;
  width: 29.5vw;
}
#card2 {
  top: 19%;
  left: 36%;
  height: 73vh;
  width: 28.5vw;
}
#card3 {
  top: 19%;
  left: 64.6%;
  height: 73vh;
  width: 30.5vw;
  padding: 2.5%;
}
#card1 .profile {
  position: relative;
  width: 95%;
}
/* #card1 .profile .photo {
      position: absolute;
      top: 4%;
      height: 24vh;
      left: 50%;
      transform: translateX(-50%);
    } */
#card1 .profile .attrs {
  margin-top: 6%;
}
#card1 .title {
  color: #f4cb41;
  margin: 10px 0;
}
#card1 .content .text {
  color: white;
  font-size: 22px;
  margin: 7px 0;
}

#card2 .title {
  width: 80%;
  margin: 7%;
}
#card2 .line {
  width: 100%;
}
#card2 .slogan {
  color: white;
  font-size: 36px;
  margin: 5px;
}
.room-wrapper {
  width: 100%;
}
#card2 .room {
  margin: 18px 0;
  height: 105px;
}
#card2 .room .img {
  width: 30%;
  background-size: cover;
  background-position: center;
}
#card2 .room .intro {
  color: white;
  font-size: 31px;
  margin: 0 24px;
}
#card2 .room .intro .name {
  font-size: 31px;
}
#card2 .room .intro .price {
  font-size: 24px;
}
#card2 .room .button-wrapper {
  position: relative;
}
#card2 .room .button-wrapper .button {
  height: 45px;
  margin: 20px;
}
#card2 .room .button-wrapper .button-hover {
  margin: 20px;
  position: absolute;
  top: -60px;
  z-index: 10;
  height: 60px;
}
#card3 .title {
  font-size: 33px;
  margin: 5px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
}
#card3 .title .prefix {
  color: #f8ea21;
}
#card3 .title .postfix {
  color: white;
}
#card3 .place-img {
  height: 47%;
  margin: 10px;
  background-size: cover;
  background-position: center;
}
#card3 .place-intro {
  font-size: 26px;
  margin: 20px 10px;
  color: white;
  overflow: hidden;
}
#card3 .button-wrapper .button {
  height: 100px;
  margin: 20px 14px;
}
/* #main {
  background-image: url('../assets/page-3-background.svg');
}
.card {
  border-radius: 40px;
  background-color: white;
  height: 68vh;
  box-sizing: border-box;
}
#card1 {
  height: 68vh;
  width: 30.3vw;
  position: absolute;
  top: 18.5%;
  left: 11.2%;
  padding: 2.5%;
  padding-top: 2%;
}
#card2 {
  height: 68vh;
  width: 51.3vw;
  position: absolute;
  top: 18.5%;
  left: 42.8%;
  padding: 2%;
}
#card1 .title {
  width: 50%;
  margin-bottom: 3vw;
}
#card1 .content {
  position: relative;
}
#card1 .content .photo {
  position: absolute;
  top: -10%;
  height: 24vh;
  left: 50%;
  transform: translateX(-50%);
}
#card1 .bottom {
  padding: 21px 30px 0;
  box-sizing: border-box;
}
#card1 .bottom .logo {
  width: 90px;
  margin: 0 15px;
}
#card1 .bottom .re-camera {
  width: 240px;
  margin: 0 15px;
}
#card2 .subtitle {
  font-size: 32px;
  margin: 6px 0;
  color: #ff9412;
}
#card2 .content .text {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  text-align: left;
}

#card2 .bottom .text {
  font-size: 20px;
  font-weight: bold;
  line-height: 36px;
  text-align: left;
}

#card2 .top {
  padding: 0 7% 3%;
  padding-right: 0;
  box-sizing: border-box;
}
#card2 .top .left .score {
  width: 240px;
  margin-top: 25px;
}
#card2 .top .right .content {
  margin-top: 16px;
}
#card2 .bottom {
  padding: 1% 0 2%;
}
#card2 .bottom .left {
  padding: 0 6%;
}
#card2 .bottom .right {
  text-align: center;
}
#card2 .bottom .right .text {
  margin: 15px 0px;
}

#card2 .bottom .artboard {
  height: 142px;
  margin-top: 18px;
  background-size: cover;
  color: white;
  font-size: 70px;
  line-height: 142px;
  padding-left: 85px;
  width: 165px;
  text-align: center;
} */

#border2-top {
  width: 100%;
}

.element-fire {
  background-image: url('../assets/ico-fire.png');
}
.element-water {
  background-image: url('../assets/ico-water.png');
}
.element-earth {
  background-image: url('../assets/ico-earth.png');
}
.element-metal {
  background-image: url('../assets/ico-metal.png');
}
.element-wood {
  background-image: url('../assets/ico-wood.png');
}
</style>
