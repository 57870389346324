<template>
  <main id="main">
    <!-- <div> -->
    <div id="dashboard" class="d-flex">
      <section id="col1" class="col d-flex flex-column">
        <h1 class="title">分類搜尋</h1>
        <h2 class="subtitle">條件設定</h2>
        <div class="d-flex flex-column align-center">
          <select class="select" v-model="f1">
            <option value="" selected disabled>風格</option>
            <option value="">全部</option>
            <option value="時尚風">時尚風</option>
            <option value="景觀風">景觀風</option>
            <option value="豪華風">豪華風</option>
            <option value="簡樸風">簡樸風</option>
            <option value="溫馨風">溫馨風</option>
          </select>
          <select class="select" v-model="f2">
            <option value="-2" selected disabled>價格區間</option>
            <option value="-1">無限制</option>
            <option v-for="n in priceFilter" :key="n" :value="n * 1000"
              >{{ n * 1000 }} - {{ (n + 1) * 1000 - 1 }}
            </option>
          </select>
          <!-- <select class="select" v-model="f3">
            <option value="" selected disabled>最高價格</option>
            <option value="1000000">無限制</option>
            <option v-for="n in maxFilter" :key="n" :value="n * 1000"
              >{{ n * 1000 }}
            </option>
          </select> -->
          <div class="d-flex">
            <page3-reset class="button" @back="back()" />
            <!-- <img
              class="button"
              src="@/assets/page-3-reset.svg"
              @click="resetFilter()"
            /> -->
            <!-- <img class="button" src="@/assets/page-3-comfirm.svg" alt="" /> -->
          </div>
        </div>
      </section>
      <section id="col2" class="col">
        <h1 class="title">選項結果</h1>
        <div id="table-wrapper" class="tableFixHead">
          <table id="table" border="1">
            <thead>
              <tr>
                <th><h2 class="subtitle">客房類型</h2></th>
                <th><h2 class="subtitle">風格</h2></th>
                <th><h2 class="subtitle">床型</h2></th>
                <th><h2 class="subtitle">價格</h2></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="room"
                v-for="(hotel, index) in filerHotel"
                :key="index"
                @click="toDetail(hotel.id)"
              >
                <td class="room-main-detail">
                  <div class="d-flex">
                    <div
                      class="image"
                      :style="{
                        backgroundImage: 'url(' + getHotelImg(hotel.id) + ')'
                      }"
                    ></div>
                    <div
                      class="category flex-1 d-flex flex-column justify-center"
                    >
                      <div class="name">{{ hotel.description }}</div>
                      <!-- <div class="people">適合人數: 1</div> -->
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    class="style d-flex justify-content align-center justify-center"
                  >
                    {{ hotel.style }}
                  </div>
                </td>
                <td>
                  <div
                    class="type d-flex justify-content align-center justify-center"
                  >
                    {{ hotel.bed }}
                  </div>
                </td>
                <td>
                  <div class="price d-flex flex-column">
                    <!-- <div class="origin">TWD {{ hotel.maxPrice }}</div> -->
                    <div class="discount">TWD {{ hotel.price }}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Page3Reset from '@/components/Page3Reset'

export default {
  name: 'More',
  data() {
    return {
      f1: '',
      f2: -2
    }
  },
  components: { Page3Reset },
  beforeRouteEnter(to, from, next) {
    if (typeof from.params.imageId === 'undefined') {
      next({ name: 'Home' })
    }
    next()
  },
  mounted: function() {
    // var audio = document.createElement('AUDIO')
    // document.body.appendChild(audio)
    // audio.src = require('../assets/vocal/5.mp3')
    // document.body.addEventListener('mousemove', function() {
    //   audio.play()
    // })
    new Audio(require('../assets/vocal/5.mp3')).play()
  },
  computed: {
    getHighestPrice: function() {
      return this.$store.getters.getHighestPrice
    },
    hotels: function() {
      return this.$store.getters.getHotels(this.f1)
    },
    filerHotel: function() {
      let vm = this
      return this.hotels
        .filter(function(item) {
          if (vm.f2 < 0) {
            return true
          }
          return item.price >= vm.f2 && item.price <= vm.f2 + 999
        })
        .sort(function(a, b) {
          return a.price - b.price
        })
    },
    priceFilter: function() {
      // let vm = this
      let max = parseInt(this.getHighestPrice / 1000)
      // console.log(max)
      let filterArray = [...Array(max).keys(), max]
      return filterArray
    }
  },
  methods: {
    resetFilter: function() {
      this.f1 = ''
      this.f2 = -1
    },
    getHotelImg: function(filename) {
      return require('../assets/hotel/' + filename + '.jpg')
    },
    toDetail: function(hotelId) {
      this.$router.push({
        name: 'Detail',
        params: { hotelId: hotelId, imageId: this.$route.params.imageId }
      })
    },
    back: function() {
      // console.log('bbb')
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
#main {
  background-image: url('../assets/page-5-6-background.svg');
  /* background-image: url('@/assets/page-5-background.jpg'); */
}
/* table */
#dashboard {
  position: absolute;
  top: 19%;
  width: 90%;
  left: 5%;
  height: 74vh;
  background: #0052b0;
  border: 3px solid #0052b0;
  /* opacity: 0.8; */
}
#dashboard .col {
  width: 30%;
  height: 100%;
  background: white;
}
#dashboard #col1 {
  width: 28%;
  border-right: 3px solid #0052b0;
}
#dashboard #col2 {
  width: 72%;
}
#dashboard .col .title {
  background: #0052b0;
  color: white;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  font-size: 40px;
}
#dashboard .col .subtitle {
  color: #808080;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  font-size: 32px;
}
/* https://css-tricks.com/styling-a-select-like-its-2019/ */
#dashboard .col select.select {
  height: 60px;
  width: 390px;
  margin: 18px auto;
  font-size: 31px;
  font-weight: bold;
  color: #0052b0;
  padding: 5px 22px;
  box-sizing: border-box;
  border: 4px solid #0052b0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
          for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230052b0%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.9em auto, 100%;
}

#dashboard .col select.select option {
  font-size: 20px;
}
#dashboard .col .button {
  height: 57px;
  margin: 16px;
}
/* table */
#dashboard .col #table .subtitle {
  height: 100%;
  font-size: 28px;
}
#table,
.tableFixHead {
  /* 88px 是 title的高度 */
  height: calc(100% - 88px);
}
#table th,
#table td {
  border: 1px solid gray;
  text-align: center;
  background: white;
  padding: 0;
}
#table th {
  height: 60px;
}
#table th::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5px;
  left: 0;
  background: inherit;
  z-index: -1;
}
#table td {
  background-color: inherit;
  color: #0052b0;
  font-size: 33px;
  font-weight: normal;
}

#table td:nth-child(1) {
  width: 51%;
}

#table td:nth-child(2) {
  width: 18.5%;
}

#table td:nth-child(3) {
  width: 15%;
}

#table .room {
  height: 210px;
}
/* #table .room .room-main-detail {
  height: 210px;
} */
#table .room .image {
  width: 310px;
  background-size: cover;
  background-position: center;
  height: 210px;
}
#table .room .category {
  text-align: left;
  padding: 30px;
}
#table .room .category .name,
#table .room .style,
#table .room .type {
  color: black;
}
#table .room .category .people {
  color: #88c23f;
  font-size: 28px;
  font-weight: bold;
}
#table .room .price .origin {
  color: #ff0000;
  text-decoration: line-through;
  font-size: 26px;
  font-weight: bold;
}
#table .room .price .discount {
  color: #0053aa;
  font-size: 32px;
  font-weight: bold;
}
/* https://stackoverflow.com/questions/21168521/table-fixed-header-and-scrollable-body */
.tableFixHead {
  overflow-y: auto;
  /* height: 100px; */
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
/* th     { background:#eee; } */
</style>
