import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Loading from '../views/Loading.vue'
import Result from '../views/Result.vue'
import Statistics from '../views/Statistics.vue'
import Detail from '../views/Detail.vue'
import More from '../views/More.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/loading/:imageId',
    name: 'Loading',
    component: Loading
  },
  {
    path: '/result/:imageId',
    name: 'Result',
    component: Result
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/detail/:hotelId',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/more',
    name: 'More',
    component: More
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
