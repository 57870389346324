<template>
  <svg
    version="1.1"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 400.5 163.5"
    style="enable-background:new 0 0 400.5 163.5;"
    xml:space="preserve"
  >
    <g id="圖層_2_1_">
      <rect x="161.7" y="0.8" class="st0" width="238" height="162.5" />
      <rect x="237.7" y="0.8" class="st0" width="162" height="162" />
      <line class="st0" x1="161.2" y1="113.8" x2="237.2" y2="113.8" />
      <line class="st0" x1="316.2" y1="0.2" x2="316.2" y2="14.8" />
      <line class="st0" x1="316.2" y1="148.2" x2="316.2" y2="162.8" />
      <line class="st0" x1="401.5" y1="81.5" x2="387" y2="81.5" />
      <line class="st0" x1="253.4" y1="81.5" x2="238.9" y2="81.5" />
      <line class="st0" x1="161.2" y1="53.8" x2="237.2" y2="53.8" />
      <circle class="st1" cx="34.7" cy="113.2" r="32.7" />
      <circle class="st2" cx="34.7" cy="113.2" r="28.9" />
      <circle class="st1" cx="35.1" cy="47.2" r="32.7" />
      <circle class="st2" cx="35.1" cy="47.2" r="28.9" />
      <circle class="st1" cx="98.5" cy="47.2" r="32.6" />
      <circle class="st2" cx="98.5" cy="47.2" r="28.9" />
      <circle class="st1" cx="100" cy="113.2" r="32.7" />
      <circle class="st2" cx="100" cy="113.2" r="28.9" />
      <path
        class="st3"
        d="M30.6,31.9c-0.9,2.4-2,4.8-3.3,7l0.2,23.1h-4.2L23,45.2c-0.7,0.8-1.5,1.7-2.2,2.5c-0.7-1.5-1.5-2.9-2.4-4.3
		c3.6-3.6,6.3-7.9,8-12.7L30.6,31.9z M52.1,57v3.8L29.2,61v-3.8l9.4-0.1v-6.8l-7.5,0.1v-3.8l7.5-0.1v-5.9L30,40.7v-3.8l9.5-0.1
		C38.9,35,38,33.3,37,31.6l3.9-1.3c1.1,1.6,2.1,3.4,2.8,5.2l-3.6,1.3l11.5-0.1v3.8l-8.6,0.1l0,5.9l7.6-0.1v3.8L43,50.3v6.8L52.1,57z
		"
      />
      <path
        class="st3"
        d="M92.6,38.4c-0.9,1.9-1.9,3.7-3.1,5.4l0.1,17.8h-4l-0.1-12.9c-0.8,0.9-1.7,1.8-2.7,2.5
		c-0.7-1.3-1.5-2.6-2.4-3.7c3.5-2.7,6.3-6.2,8.2-10.3L92.6,38.4z M86.3,39.3h-4.1L82.1,33l12.7-0.1c-0.2-0.7-0.5-1.4-0.9-2l4.5-1.2
		c0.5,0.9,1,2.1,1.5,3.2l12.4-0.1v5.6H108v-2.1l-21.8,0.2L86.3,39.3z M104.5,42.6c-0.3,0.8-0.6,1.6-0.9,2.3l7.8-0.1l0.1,16.4h-4.2
		v-1.2l-9.7,0.1v1.4h-4l-0.1-16.6h5.8c0.2-0.8,0.3-1.6,0.5-2.3h-7.4v-3.5l20.6-0.2v3.5L104.5,42.6z M107.2,48.2l-9.7,0.1v2.5
		l9.7-0.1L107.2,48.2z M107.3,56.7V54l-9.7,0.1v2.7L107.3,56.7z"
      />
      <path
        class="st3"
        d="M48.4,96.6c-0.1,13.3,0,25.8,1.6,25.8c0.3,0,0.5-2,0.6-4.7c0.7,1.1,1.5,2.2,2.3,3.2c-0.4,4-1.1,5.9-3.3,5.9
		c-5.1,0-5.2-12-5.3-26.4l-17.3,0.1l0.1,8c0,5.6-0.5,13.6-4.3,18.6c-1-0.9-2.2-1.6-3.4-2.1c3.5-4.6,3.8-11.3,3.8-16.4l-0.1-11.7
		L48.4,96.6z M42.1,117.2c1.5,2.3,2.6,4.8,3.3,7.5l-3.4,1.1c-0.2-0.6-0.4-1.4-0.6-2.3c-5.2,0.5-10.5,0.9-14.4,1.3l-0.3-3.6
		c1.9-0.1,4.4-0.2,7.1-0.4v-3.5h-5.5l-0.1-9.8h5.5v-2.3H28v-3.2l15-0.1v3.2h-5.8v2.3H43l0.1,9.8h-5.7v3.3l2.6-0.1
		c-0.3-0.7-0.7-1.4-1.2-2.1L42.1,117.2z M33.7,114.2v-3.7h-2.3v3.7H33.7z M37.3,110.5v3.7h2.3v-3.7H37.3z"
      />
      <path
        class="st3"
        d="M113.1,99.1c-1.4,3.1-3.5,5.9-6.1,8.1c2.4,1.1,5,1.9,7.7,2.4c-0.6,1.2-1.1,2.5-1.3,3.8c-3.4-0.8-6.7-2-9.7-3.8
		c-2.6,1.6-5.3,2.9-8.2,3.8l16.3-0.1l0.1,12.7h-4.2v-1.7l-8.7,0.1v1.9h-4l-0.1-12.6l-1,0.3c-0.3-0.8-0.8-1.5-1.3-2.2l-0.8,1
		c-0.4-0.8-1.2-2.1-1.9-3.3l0.1,17h-4l-0.1-13.4c-0.7,2.5-1.8,4.9-3.1,7.2c-0.5-1.4-1.2-2.7-2-4c1.9-2.6,3.6-7.2,4.5-11.2h-3.8v-3.7
		h4.3l-0.1-6.3h4v6.3h3.2v1.9c2.6-2.3,4.6-5.2,5.8-8.5l3.7,1c-0.3,0.7-0.7,1.6-1,2.3l8.3-0.1l0.7-0.1L113.1,99.1z M100.3,107.2
		c-1.1-0.9-2.1-2-3-3.1c-0.6,0.8-1.3,1.5-2,2.1c-0.7-1.1-1.5-2.1-2.3-3.1v1.7h-3.1c1.5,1.5,3,3.1,4.3,4.8
		C96.3,109.1,98.4,108.3,100.3,107.2z M98.9,116.9v4l8.7-0.1v-4L98.9,116.9z M99.7,101.4c1,1.5,2.3,2.8,3.8,3.8
		c1.5-1.1,2.8-2.4,3.8-3.9L99.7,101.4z"
      />
    </g>
    <text transform="matrix(1 0 0 1 176.4028 40.3337)">
      <tspan x="0" y="0" class="st3 st4 st5">{{ c.charAt(0) }}</tspan>
      <tspan x="0" y="56.6" class="st3 st4 st5">{{ c.charAt(1) }}</tspan>
      <tspan x="0" y="113.3" class="st3 st4 st5">{{ c.charAt(2) }}</tspan>
    </text>
    <text transform="matrix(1 0 0 1 260.45 116.7201)" class="st3 st4 st6">
      {{ score }}
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      default: 99
    },
    category: {
      type: String,
      default: ''
    }
  },
  computed: {
    c: function() {
      return this.category
    }
  }
}
</script>

<style scoped>
.st0 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #009aff;
}
.st2 {
  fill: #009aff;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  font-family: 'NotoSansCJKtc-Bold-B5pc-H';
}
.st5 {
  font-size: 40.527px;
}
.st6 {
  font-size: 97.4197px;
}
</style>
