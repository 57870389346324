<template>
  <main id="main">
    <section id="card1" class="card d-flex flex-column">
      <h1 class="title yellow-font">房間介紹</h1>
      <h2 class="subtitle" v-if="hotel">{{ hotel.description }}</h2>
      <div class="flex-1">
        <div
          v-if="hotel"
          class="image w-100"
          :style="{
            backgroundImage: 'url(' + getHotelImg(hotel.id) + ')'
          }"
        ></div>
        <!-- <img
          class="image w-100"
          src="https://dummyimage.com/640x290/000/fff"
          alt=""
        /> -->
      </div>
      <div class="content d-flex">
        <div class="info" v-if="hotel">
          <span>{{ hotel.description }}</span
          ><br />
          <span class="yellow-font">Ｖ{{ hotel.bed }}</span
          ><br />
          <span class="yellow-font">Ｖ免費取消</span><br />
          <span>包含{{ hotel.breakfast }}</span>
          <br />
        </div>
        <div class="d-flex">
          <div class="button-wrapper d-flex flex-column">
            <img
              class="button"
              src="@/assets/page-7-cancel.svg"
              @click="back()"
            />
          </div>
          <div class="button-wrapper d-flex flex-column">
            <page7-price
              class="button-hover"
              v-if="hotel"
              :price="hotel.price"
            />
            <!-- <img class="button-hover" src="@/assets/page-7-price.svg" alt="" /> -->
            <img
              class="button"
              src="@/assets/page-7-booking.svg"
              @click="openDialog()"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="card2" class="card d-flex flex-column">
      <h1 class="title yellow-font">景點推薦</h1>
      <div class="flex-1">
        <div
          class="image w-100"
          v-if="hotel"
          :style="{
            backgroundImage: 'url(' + getAttractionsImg(hotel.id) + ')'
          }"
        ></div>
      </div>
      <p class="content">
        {{ hotel.attractionDescription }}
      </p>
    </section>
    <div id="dialog1" class="dialog" v-show="showDialog">
      <img class="bg" src="@/assets/page-7-dialog1.svg" alt="" />
      <div class="button-wrapper d-flex justify-center align-center">
        <img
          class="button"
          src="@/assets/page-7-check.svg"
          @click="openDialog2()"
        />
        <img
          class="button"
          src="@/assets/page-7-cancel.svg"
          @click="cancelDialog()"
        />
      </div>
    </div>
    <div id="dialog2" class="dialog" v-show="showDialog2">
      <img class="bg" src="@/assets/page-7-dialog2.svg" alt="" />
      <div class="button-wrapper d-flex justify-center align-center">
        <img class="button" src="@/assets/page-7-exit.svg" @click="toHome()" />
      </div>
    </div>
  </main>
</template>

<script>
// import Page7Price from '@/components/Page7Price'

export default {
  name: 'Detail',
  data() {
    return {
      showDialog: false,
      showDialog2: false
    }
  },
  // components: { Page7Price },
  beforeRouteEnter(to, from, next) {
    if (typeof from.params.imageId === 'undefined') {
      next({ name: 'Home' })
    }
    next()
  },
  mounted: function() {
    new Audio(require('../assets/vocal/4.mp3')).play()
  },
  computed: {
    hotel: function() {
      return this.$store.getters.getHotelById(this.$route.params.hotelId)
    },
    attraction: function() {
      return this.$store.getters.getAttractionsById(this.$route.params.hotelId)
    }
  },
  methods: {
    openDialog() {
      new Audio(require('../assets/vocal/4.mp3')).play()
      this.showDialog = true
    },
    cancelDialog() {
      this.showDialog = false
    },
    openDialog2() {
      this.sendTrace()
      new Audio(require('../assets/vocal/6.mp3')).play()
      this.showDialog = false
      this.showDialog2 = true
    },
    toHome: function() {
      this.$router.push({ name: 'Home' })
    },
    getHotelImg: function(filename) {
      return require('../assets/hotel/' + filename + '.jpg')
    },
    getAttractionsImg: function(filename) {
      return require('../assets/attractions/' + padLeft(filename, 4) + '.jpg')
    },
    back: function() {
      this.$router.go(-1)
    },
    sendTrace: function() {
      this.$store.dispatch('postTrace', {
        price: this.hotel.price,
        imageId: this.$route.params.imageId,
        productId: this.hotel.id,
        productName: this.hotel.description
      })
    }
  }
}

function padLeft(str, len) {
  str = '' + str
  return str.length >= len
    ? str
    : new Array(len - str.length + 1).join('0') + str
}
</script>

<style scoped>
#main {
  background-image: url('../assets/page-7-background.svg');
  /* background-image: url('./assert/page-7-background-2.jpg'); */
}
/* dialig */
.dialog {
  position: fixed;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 755px;
  z-index: 20;
}
.dialog .button-wrapper {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.dialog .button-wrapper .button {
  height: 65px;
  margin: 0 40px;
}
.card {
  padding: 1.2% 5.2%;
  box-sizing: border-box;
  /* background: #868686; */
  /* opacity: 0.8; */
}
.card .yellow-font {
  color: #f4cb42;
}
.card .title {
  height: 95px;
  line-height: 95px;
  font-size: 50px;
  text-align: center;
  margin: 0;
}
.card .subtitle {
  color: white;
  font-size: 40px;
  margin: 14px 0;
}
.card .content {
  color: white;
  font-size: 25px;
  font-weight: bold;
}
#card1 {
  position: fixed;
  top: 21%;
  left: 5.5%;
  height: 69vh;
  width: 44vw;
}
.image {
  height: 100%;
  background-size: cover;
  background-position: center;
}
#card2 {
  position: fixed;
  top: 21%;
  left: 51.5%;
  height: 69vh;
  width: 44.5vw;
}
#card1 .content {
  justify-content: space-between;
  padding: 15px 0 64px;
  box-sizing: border-box;
  font-size: 25px;
}
#card1 .button-wrapper {
  position: relative;
  justify-content: flex-end;
}
#card1 .button-wrapper .button {
  height: 48px;
  margin-left: 15px;
}
#card1 .button-wrapper .button-hover {
  position: absolute;
  top: -58px;
  width: 265px;
  left: 50%;
  transform: translateX(-50%);
}
#card2 .content {
  margin: 0 0 42px;
}
</style>
