<template>
  <main id="main">
    <div id="table-wrapper" class="tableFixHead">
      <table id="table" border="1">
        <thead>
          <tr>
            <th>項目名稱</th>
            <th>面相類型</th>
            <th>分析數量</th>
          </tr>
        </thead>
        <tbody v-if="prepared">
          <tr v-for="(item, index) in result[0].child" :key="item.name">
            <td :rowspan="result[0].child.length" v-if="index === 0">
              {{ result[0].name }}
            </td>
            <td class="d-none" v-else></td>
            <!-- <td>{{ item.parent }}型人</td> -->
            <td>{{ item.name }}</td>
            <td>{{ item.ratio }}%</td>
          </tr>
          <tr v-for="(item, index) in result[1].child" :key="item.name">
            <td :rowspan="result[1].child.length" v-if="index === 0">
              {{ result[1].name }}
            </td>
            <td class="d-none" v-else></td>
            <!-- <td>{{ item.parent }}型人</td> -->
            <td>{{ item.name }} - {{ (item.name / 1000 + 1) * 1000 }}</td>
            <td>{{ item.ratio }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button id="export">
      <a
        target="_blank"
        href="https://api.taotaoxi.net/face-reading-must/tourism-statistics"
        >匯出</a
      >
    </button>
  </main>
</template>

<script>
export default {
  name: 'Statistics',
  data() {
    return { result: [], prepared: false }
  },
  mounted: function() {
    let vm = this
    vm.$store.commit('toggleLoading')
    this.$store.dispatch('getStatistics').then(function(data) {
      data[1].child.sort(function(a, b) {
        return a.name - b.name
      })
      vm.result = data
      vm.prepared = true
      vm.$store.commit('toggleLoading')
    })
  }
}
</script>

<style scoped>
/* https://stackoverflow.com/questions/21168521/table-fixed-header-and-scrollable-body */
.tableFixHead {
  overflow-y: auto;
  height: 100px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
/* th     { background:#eee; } */
#main {
  background-image: url('../assets/page-5-6-background.svg');
}
/* table */
#table-wrapper {
  position: absolute;
  top: 12%;
  width: 91%;
  left: 4.5%;
  height: 78vh;
  background: #0052b0;
  border: 3px solid #0052b0;
}
#table th,
#table td {
  border: 3px solid #0052b0;
  text-align: center;
}
#table th {
  background: #0052b0;
  color: white;
  height: 70px;
  font-size: 30px;
  font-weight: normal;
}
#table th::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5px;
  left: 0;
  background: #0052b0;
  z-index: -1;
}
#table tr:nth-child(odd) {
  background: white;
}
#table tr:nth-child(even) {
  background: #c3f2ff;
}
#table td {
  background-color: inherit;
  color: #0052b0;
  height: 35px;
  font-size: 35px;
  font-weight: normal;
}
#table td[rowspan] {
  background-color: white;
}
/* button */
#export {
  background: #0052b0;
  color: white;
  font-size: 30px;
  padding: 8px 23px;
  border-radius: 15px;
  position: fixed;
  bottom: 2%;
  right: 4%;
}
</style>
